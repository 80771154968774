define("discourse/plugins/discourse-adplugin/discourse/templates/components/house-ad", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showAd}}
    {{html-safe adHtml}}
  {{/if}}
  */
  {
    "id": "5v6Mtqtl",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"adHtml\"]]],null]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showAd` property path was used in the `discourse/plugins/discourse-adplugin/discourse/templates/components/house-ad.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showAd}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `adHtml` property path was used in the `discourse/plugins/discourse-adplugin/discourse/templates/components/house-ad.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.adHtml}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/templates/components/house-ad.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});