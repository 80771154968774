define("discourse/plugins/discourse-adplugin/initializers/initialize-ad-plugin", ["exports", "discourse/lib/plugin-api", "discourse/models/site"], function (_exports, _pluginApi, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "initialize-ad-plugin",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        api.decorateWidget("post:after", dec => {
          if (dec.canConnectComponent) {
            if (!dec.attrs.cloaked) {
              return dec.connect({
                component: "post-bottom-ad",
                context: "model"
              });
            }
          } else {
            // Old way for backwards compatibility
            return dec.connect({
              templateName: "connectors/post-bottom/discourse-adplugin",
              context: "model"
            });
          }
        });
      });
      const messageBus = container.lookup("message-bus:main");
      if (!messageBus) {
        return;
      }
      const currentUser = container.lookup("service:current-user");
      let channel;
      if (currentUser) {
        channel = "/site/house-creatives/logged-in";
      } else {
        channel = "/site/house-creatives/anonymous";
      }
      messageBus.subscribe(channel, function (houseAdsSettings) {
        _site.default.currentProp("house_creatives", houseAdsSettings);
      });
    }
  };
});