define("discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "@ember/template"], function (_exports, _adComponent, _decorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _adComponent.default.extend((_dec = (0, _decorators.default)("serve_id", "placement"), _dec2 = (0, _decorators.default)("currentUser.trust_level"), _dec3 = (0, _decorators.default)("placement", "serve_id", "showToTrustLevel", "showToGroups", "showOnCurrentPage"), (_obj = {
    serve_id: null,
    placement: null,
    init() {
      this.set("serve_id", this.siteSettings.carbonads_serve_id);
      this.set("placement", this.siteSettings.carbonads_placement);
      this._super();
    },
    url(serveId, placement) {
      return (0, _template.htmlSafe)(`//cdn.carbonads.com/carbon.js?serve=${serveId}&placement=${placement}`);
    },
    showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.carbonads_through_trust_level);
    },
    showAd(placement, serveId, showToTrustLevel, showToGroups, showOnCurrentPage) {
      return placement && serveId && showToTrustLevel && showToGroups && showOnCurrentPage;
    }
  }, (_applyDecoratedDescriptor(_obj, "url", [_dec], Object.getOwnPropertyDescriptor(_obj, "url"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj)), _obj)));
});