define("discourse/plugins/discourse-adplugin/discourse/components/google-adsense", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "discourse/lib/load-script", "rsvp", "@ember/runloop", "discourse-common/config/environment", "@ember/template"], function (_exports, _adComponent, _decorators, _loadScript, _rsvp, _runloop, _environment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let _loaded = false,
    _promise = null,
    renderCounts = {};
  function parseAdWidth(value) {
    if (value === "responsive") {
      return "auto";
    }
    if (value.startsWith("fluid")) {
      return "fluid";
    }
    const w = parseInt(value.substring(0, 3).trim(), 10);
    if (isNaN(w)) {
      return "auto";
    } else {
      return `${w}px`;
    }
  }
  function parseAdHeight(value) {
    if (value === "responsive") {
      return "auto";
    }
    if (value.startsWith("fluid")) {
      return "fluid";
    }
    const h = parseInt(value.substring(4, 7).trim(), 10);
    if (isNaN(h)) {
      return "auto";
    } else {
      return `${h}px`;
    }
  }
  function loadAdsense() {
    if (_loaded) {
      return _rsvp.default.resolve();
    }
    if (_promise) {
      return _promise;
    }
    const adsenseSrc = ("https:" === document.location.protocol ? "https:" : "http:") + "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    _promise = (0, _loadScript.default)(adsenseSrc, {
      scriptTag: true
    }).then(function () {
      _loaded = true;
    });
    return _promise;
  }
  const DESKTOP_SETTINGS = {
    "topic-list-top": {
      code: "adsense_topic_list_top_code",
      sizes: "adsense_topic_list_top_ad_sizes"
    },
    "topic-above-post-stream": {
      code: "adsense_topic_above_post_stream_code",
      sizes: "adsense_topic_above_post_stream_ad_sizes"
    },
    "topic-above-suggested": {
      code: "adsense_topic_above_suggested_code",
      sizes: "adsense_topic_above_suggested_ad_sizes"
    },
    "post-bottom": {
      code: "adsense_post_bottom_code",
      sizes: "adsense_post_bottom_ad_sizes"
    }
  };
  const MOBILE_SETTINGS = {
    "topic-list-top": {
      code: "adsense_mobile_topic_list_top_code",
      sizes: "adsense_mobile_topic_list_top_ad_size"
    },
    "topic-above-post-stream": {
      code: "adsense_mobile_topic_above_post_stream_code",
      sizes: "adsense_mobile_topic_above_post_stream_ad_size"
    },
    "topic-above-suggested": {
      code: "adsense_mobile_topic_above_suggested_code",
      sizes: "adsense_mobile_topic_above_suggested_ad_size"
    },
    "post-bottom": {
      code: "adsense_mobile_post_bottom_code",
      sizes: "adsense_mobile_post_bottom_ad_size"
    }
  };
  var _default = _exports.default = _adComponent.default.extend((_dec = (0, _decorators.observes)("listLoading"), _dec2 = (0, _decorators.default)("ad_width"), _dec3 = (0, _decorators.default)("ad_width"), _dec4 = (0, _decorators.default)("placement", "showAd"), _dec5 = (0, _decorators.default)("isResponsive", "isFluid"), _dec6 = (0, _decorators.default)("ad_width", "ad_height", "isResponsive"), _dec7 = (0, _decorators.default)("adWrapperStyle", "isResponsive"), _dec8 = (0, _decorators.default)("currentUser.trust_level"), _dec9 = (0, _decorators.default)("publisher_id", "showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage"), _dec10 = (0, _decorators.default)("postNumber"), (_obj = {
    classNameBindings: [":google-adsense", "classForSlot", "isResponsive:adsense-responsive"],
    loadedGoogletag: false,
    publisher_id: null,
    ad_width: null,
    ad_height: null,
    adRequested: false,
    init() {
      let config, size;
      const placement = this.get("placement");
      if (this.site.mobileView) {
        config = MOBILE_SETTINGS[placement];
      } else {
        config = DESKTOP_SETTINGS[placement];
      }
      if (!renderCounts[placement]) {
        renderCounts[placement] = 0;
      }
      const sizes = (this.siteSettings[config.sizes] || "").split("|");
      if (sizes.length === 1) {
        size = sizes[0];
      } else {
        size = sizes[renderCounts[placement] % sizes.length];
        renderCounts[placement] += 1;
      }
      this.set("ad_width", parseAdWidth(size));
      this.set("ad_height", parseAdHeight(size));
      this.set("ad_code", this.siteSettings[config.code]);
      this.set("publisher_id", this.siteSettings.adsense_publisher_code);
      this._super();
    },
    _triggerAds() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }
      this.set("adRequested", true);
      loadAdsense().then(function () {
        const adsbygoogle = window.adsbygoogle || [];
        try {
          adsbygoogle.push({}); // ask AdSense to fill one ad unit
        } catch (ex) {}
      });
    },
    didInsertElement() {
      this._super();
      if (!this.get("showAd")) {
        return;
      }
      if (this.get("listLoading")) {
        return;
      }
      (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
    },
    waitForLoad() {
      if (this.get("adRequested")) {
        return;
      } // already requested that this ad unit be populated
      if (!this.get("listLoading")) {
        (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
      }
    },
    isResponsive(adWidth) {
      return ["auto", "fluid"].includes(adWidth);
    },
    isFluid(adWidth) {
      return adWidth === "fluid";
    },
    classForSlot(placement, showAd) {
      return showAd ? (0, _template.htmlSafe)(`adsense-${placement}`) : "";
    },
    autoAdFormat(isResponsive, isFluid) {
      return isResponsive ? (0, _template.htmlSafe)(isFluid ? "fluid" : "auto") : false;
    },
    adWrapperStyle(w, h, isResponsive) {
      return (0, _template.htmlSafe)(isResponsive ? "" : `width: ${w}; height: ${h};`);
    },
    adInsStyle(adWrapperStyle, isResponsive) {
      return (0, _template.htmlSafe)(`display: ${isResponsive ? "block" : "inline-block"}; ${adWrapperStyle}`);
    },
    showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.adsense_through_trust_level);
    },
    showAd(publisherId, showToTrustLevel, showToGroups, showAfterPost, showOnCurrentPage) {
      return publisherId && showToTrustLevel && showToGroups && showAfterPost && showOnCurrentPage;
    },
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.adsense_nth_post_code, 10));
    }
  }, (_applyDecoratedDescriptor(_obj, "waitForLoad", [_dec], Object.getOwnPropertyDescriptor(_obj, "waitForLoad"), _obj), _applyDecoratedDescriptor(_obj, "isResponsive", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isResponsive"), _obj), _applyDecoratedDescriptor(_obj, "isFluid", [_dec3], Object.getOwnPropertyDescriptor(_obj, "isFluid"), _obj), _applyDecoratedDescriptor(_obj, "classForSlot", [_dec4], Object.getOwnPropertyDescriptor(_obj, "classForSlot"), _obj), _applyDecoratedDescriptor(_obj, "autoAdFormat", [_dec5], Object.getOwnPropertyDescriptor(_obj, "autoAdFormat"), _obj), _applyDecoratedDescriptor(_obj, "adWrapperStyle", [_dec6], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyle"), _obj), _applyDecoratedDescriptor(_obj, "adInsStyle", [_dec7], Object.getOwnPropertyDescriptor(_obj, "adInsStyle"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec8], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec9], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec10], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj)), _obj)));
});