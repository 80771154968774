define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads-index", ["exports", "discourse/routes/discourse", "@ember/object"], function (_exports, _discourse, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    moreSettings() {
      this.transitionTo("adminSiteSettingsCategory", "ad_plugin");
    }
  }, (_applyDecoratedDescriptor(_obj, "moreSettings", [_object.action], Object.getOwnPropertyDescriptor(_obj, "moreSettings"), _obj)), _obj));
});