define("discourse/plugins/discourse-adplugin/discourse/components/adbutler-ad", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "discourse/lib/load-script", "rsvp", "@ember/runloop", "discourse-common/config/environment"], function (_exports, _adComponent, _decorators, _loadScript, _rsvp, _runloop, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let _loaded = false,
    _promise = null,
    _c = 0;
  function loadAdbutler(adserverHostname) {
    if (_loaded) {
      return _rsvp.default.resolve();
    }
    if (_promise) {
      return _promise;
    }
    _promise = (0, _loadScript.default)("https://" + adserverHostname + "/app.js", {
      scriptTag: true
    }).then(function () {
      _loaded = true;
    });
    return _promise;
  }
  var _default = _exports.default = _adComponent.default.extend((_dec = (0, _decorators.observes)("listLoading"), _dec2 = (0, _decorators.default)("currentUser.trust_level"), _dec3 = (0, _decorators.default)("publisherId", "showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage"), _dec4 = (0, _decorators.default)("postNumber"), (_obj = {
    divs: null,
    publisherId: null,
    init() {
      let dimensions = [728, 90];
      let configKey = "adbutler_";
      let className = "adbutler-";
      let dimClassName = "adbutler-ad";
      this.set("divs", []);
      if (this.site.mobileView) {
        dimensions = [320, 50];
        configKey += "mobile_";
        className += "mobile-";
        dimClassName = "adbutler-mobile-ad";
      }
      configKey += this.get("placement").replace(/-/g, "_") + "_zone_id";
      this.set("configKey", configKey);
      className += this.get("placement");
      this.set("className", className + " " + dimClassName);
      let zoneId = this.siteSettings[configKey];
      this.set("zoneId", zoneId);
      let divId = "placement-" + zoneId + "-" + _c;
      this.set("divId", divId);
      let publisherId = this.siteSettings.adbutler_publisher_id;
      this.set("publisherId", publisherId);
      _c++;
      this.divs.push({
        divId,
        publisherId,
        zoneId,
        dimensions
      });
      this._super();
    },
    _triggerAds() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }
      const adserverHostname = this.siteSettings.adbutler_adserver_hostname;
      loadAdbutler(adserverHostname).then(function () {
        if (this.divs.length > 0) {
          let abkw = window.abkw || "";
          window.AdButler.ads.push({
            handler: function (opt) {
              window.AdButler.register(opt.place.publisherId, opt.place.zoneId, opt.place.dimensions, opt.place.divId, opt);
            },
            opt: {
              place: this.divs.pop(),
              keywords: abkw,
              domain: adserverHostname,
              click: "CLICK_MACRO_PLACEHOLDER"
            }
          });
        }
      }.bind(this));
    },
    didInsertElement() {
      this._super();
      (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
    },
    waitForLoad() {
      if (this.get("adRequested")) {
        return;
      } // already requested that this ad unit be populated
      if (!this.get("listLoading")) {
        (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
      }
    },
    showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.adbutler_through_trust_level);
    },
    showAd(publisherId, showToTrustLevel, showToGroups, showAfterPost, showOnCurrentPage) {
      return publisherId && showToTrustLevel && showToGroups && showAfterPost && showOnCurrentPage;
    },
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.adbutler_nth_post, 10));
    }
  }, (_applyDecoratedDescriptor(_obj, "waitForLoad", [_dec], Object.getOwnPropertyDescriptor(_obj, "waitForLoad"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj)), _obj)));
});