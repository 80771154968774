define("discourse/plugins/discourse-adplugin/discourse/components/amazon-product-links", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/template"], function (_exports, _adComponent, _decorators, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _adComponent.default.extend((_dec = (0, _decorators.default)("amazon_width", "amazon_height"), _dec2 = (0, _decorators.default)("mobile_amazon_width", "mobile_amazon_height"), _dec3 = (0, _decorators.default)("mobile_amazon_width"), _dec4 = (0, _decorators.default)("user_input"), _dec5 = (0, _decorators.default)("user_input_mobile"), _dec6 = (0, _decorators.default)("currentUser.trust_level"), _dec7 = (0, _decorators.default)("postNumber"), (_obj = {
    classNames: ["amazon-product-links"],
    showAd: (0, _computed.and)("showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage"),
    init() {
      const data = {
        "topic-list-top": {},
        "topic-above-post-stream": {},
        "topic-above-suggested": {},
        "post-bottom": {}
      };
      const mobileView = this.site.get("mobileView");
      const placement = this.get("placement");
      if (!mobileView && this.siteSettings.amazon_topic_list_top_src_code) {
        data["topic-list-top"]["user_input"] = this.siteSettings.amazon_topic_list_top_src_code;
        data["topic-list-top"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_list_top_ad_width_code, 10);
        data["topic-list-top"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_list_top_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_topic_list_top_src_code) {
        data["topic-list-top"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_list_top_src_code;
        data["topic-list-top"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_list_top_ad_width_code, 10);
        data["topic-list-top"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_list_top_ad_height_code, 10);
      }
      if (!mobileView && this.siteSettings.amazon_topic_above_post_stream_src_code) {
        data["topic-above-post-stream"]["user_input"] = this.siteSettings.amazon_topic_above_post_stream_src_code;
        data["topic-above-post-stream"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_above_post_stream_ad_width_code, 10);
        data["topic-above-post-stream"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_above_post_stream_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_topic_above_post_stream_src_code) {
        data["topic-above-post-stream"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_above_post_stream_src_code;
        data["topic-above-post-stream"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_above_post_stream_ad_width_code, 10);
        data["topic-above-post-stream"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_above_post_stream_ad_height_code, 10);
      }
      if (!mobileView && this.siteSettings.amazon_topic_above_suggested_src_code) {
        data["topic-above-suggested"]["user_input"] = this.siteSettings.amazon_topic_above_suggested_src_code;
        data["topic-above-suggested"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_above_suggested_ad_width_code, 10);
        data["topic-above-suggested"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_above_suggested_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_topic_above_suggested_src_code) {
        data["topic-above-suggested"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_above_suggested_src_code;
        data["topic-above-suggested"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_above_suggested_ad_width_code, 10);
        data["topic-above-suggested"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_above_suggested_ad_height_code, 10);
      }
      if (!mobileView && this.siteSettings.amazon_post_bottom_src_code) {
        data["post-bottom"]["user_input"] = this.siteSettings.amazon_post_bottom_src_code;
        data["post-bottom"]["amazon_width"] = parseInt(this.siteSettings.amazon_post_bottom_ad_width_code, 10);
        data["post-bottom"]["amazon_height"] = parseInt(this.siteSettings.amazon_post_bottom_ad_height_code, 10);
      }
      if (mobileView && this.siteSettings.amazon_mobile_post_bottom_src_code) {
        data["post-bottom"]["user_input_mobile"] = this.siteSettings.amazon_mobile_post_bottom_src_code;
        data["post-bottom"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_post_bottom_ad_width_code, 10);
        data["post-bottom"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_post_bottom_ad_height_code, 10);
      }
      this.set("user_input", data[placement]["user_input"]);
      this.set("amazon_width", data[placement]["amazon_width"]);
      this.set("amazon_height", data[placement]["amazon_height"]);
      this.set("user_input_mobile", data[placement]["user_input_mobile"]);
      this.set("mobile_amazon_height", data[placement]["mobile_amazon_height"]);
      this.set("mobile_amazon_width", data[placement]["mobile_amazon_width"]);
      this._super();
    },
    adWrapperStyle(w, h) {
      return (0, _template.htmlSafe)(`width: ${w}px; height: ${h}px;`);
    },
    adWrapperStyleMobile(w, h) {
      return (0, _template.htmlSafe)(`width: ${w}px; height: ${h}px;`);
    },
    adTitleStyleMobile(w) {
      return (0, _template.htmlSafe)(`width: ${w}px;`);
    },
    userInput(userInput) {
      return (0, _template.htmlSafe)(`${userInput}`);
    },
    userInputMobile(userInput) {
      return (0, _template.htmlSafe)(`${userInput}`);
    },
    showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.amazon_through_trust_level);
    },
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.amazon_nth_post_code, 10));
    }
  }, (_applyDecoratedDescriptor(_obj, "adWrapperStyle", [_dec], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyle"), _obj), _applyDecoratedDescriptor(_obj, "adWrapperStyleMobile", [_dec2], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyleMobile"), _obj), _applyDecoratedDescriptor(_obj, "adTitleStyleMobile", [_dec3], Object.getOwnPropertyDescriptor(_obj, "adTitleStyleMobile"), _obj), _applyDecoratedDescriptor(_obj, "userInput", [_dec4], Object.getOwnPropertyDescriptor(_obj, "userInput"), _obj), _applyDecoratedDescriptor(_obj, "userInputMobile", [_dec5], Object.getOwnPropertyDescriptor(_obj, "userInputMobile"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec6], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj)), _obj)));
});