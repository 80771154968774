define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting", ["exports", "@ember/component", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "I18n"], function (_exports, _component, _ajax, _ajaxError, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "house-ads-setting",
    adValue: "",
    saving: false,
    savingStatus: "",
    title: (0, _computed.i18n)("name", "admin.adplugin.house_ads.%@.title"),
    help: (0, _computed.i18n)("name", "admin.adplugin.house_ads.%@.description"),
    changed: (0, _computed.propertyNotEqual)("adValue", "value"),
    init() {
      this._super(...arguments);
      this.set("adValue", this.get("value"));
    },
    actions: {
      save() {
        if (!this.get("saving")) {
          this.setProperties({
            saving: true,
            savingStatus: _I18n.default.t("saving")
          });
          (0, _ajax.ajax)(`/admin/plugins/pluginad/house_settings/${this.get("name")}.json`, {
            type: "PUT",
            data: {
              value: this.get("adValue")
            }
          }).then(() => {
            const adSettings = this.get("adSettings");
            adSettings.set(this.get("name"), this.get("adValue"));
            this.setProperties({
              value: this.get("adValue"),
              savingStatus: _I18n.default.t("saved")
            });
          }).catch(_ajaxError.popupAjaxError).finally(() => {
            this.setProperties({
              saving: false,
              savingStatus: ""
            });
          });
        }
      },
      cancel() {
        this.set("adValue", this.get("value"));
      }
    }
  });
});