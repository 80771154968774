define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-list-setting", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting", "@ember/object/computed"], function (_exports, _houseAdsSetting, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _houseAdsSetting.default.extend({
    classNames: "house-ads-setting house-ads-list-setting",
    adNames: (0, _computed.mapBy)("allAds", "name")
  });
});