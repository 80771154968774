define("discourse/plugins/discourse-adplugin/discourse/components/google-dfp-ad", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "discourse/lib/load-script", "@ember/object/computed", "rsvp", "discourse-common/config/environment", "@ember/template"], function (_exports, _adComponent, _decorators, _loadScript, _computed, _rsvp, _environment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let _loaded = false,
    _promise = null,
    ads = {},
    nextSlotNum = 1,
    renderCounts = {};
  function getNextSlotNum() {
    return nextSlotNum++;
  }
  function splitWidthInt(value) {
    let str = value.substring(0, 3);
    return str.trim();
  }
  function splitHeightInt(value) {
    let str = value.substring(4, 7);
    return str.trim();
  }

  // This creates an array for the values of the custom targeting key
  function valueParse(value) {
    let final = value.replace(/ /g, "");
    final = final.replace(/['"]+/g, "");
    final = final.split(",");
    return final;
  }

  // This creates an array for the key of the custom targeting key
  function keyParse(word) {
    let key = word;
    key = key.replace(/['"]+/g, "");
    key = key.split("\n");
    return key;
  }

  // This should call adslot.setTargeting(key for that location, value for that location)
  function custom_targeting(key_array, value_array, adSlot) {
    for (let i = 0; i < key_array.length; i++) {
      if (key_array[i]) {
        adSlot.setTargeting(key_array[i], valueParse(value_array[i]));
      }
    }
  }
  const DESKTOP_SETTINGS = {
    "topic-list-top": {
      code: "dfp_topic_list_top_code",
      sizes: "dfp_topic_list_top_ad_sizes",
      targeting_keys: "dfp_target_topic_list_top_key_code",
      targeting_values: "dfp_target_topic_list_top_value_code"
    },
    "topic-above-post-stream": {
      code: "dfp_topic_above_post_stream_code",
      sizes: "dfp_topic_above_post_stream_ad_sizes",
      targeting_keys: "dfp_target_topic_above_post_stream_key_code",
      targeting_values: "dfp_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "dfp_topic_above_suggested_code",
      sizes: "dfp_topic_above_suggested_ad_sizes",
      targeting_keys: "dfp_target_topic_above_suggested_key_code",
      targeting_values: "dfp_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "dfp_post_bottom_code",
      sizes: "dfp_post_bottom_ad_sizes",
      targeting_keys: "dfp_target_post_bottom_key_code",
      targeting_values: "dfp_target_post_bottom_value_code"
    }
  };
  const MOBILE_SETTINGS = {
    "topic-list-top": {
      code: "dfp_mobile_topic_list_top_code",
      sizes: "dfp_mobile_topic_list_top_ad_sizes",
      targeting_keys: "dfp_target_topic_list_top_key_code",
      targeting_values: "dfp_target_topic_list_top_value_code"
    },
    "topic-above-post-stream": {
      code: "dfp_mobile_topic_above_post_stream_code",
      sizes: "dfp_mobile_topic_above_post_stream_ad_sizes",
      targeting_keys: "dfp_target_topic_above_post_stream_key_code",
      targeting_values: "dfp_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "dfp_mobile_topic_above_suggested_code",
      sizes: "dfp_mobile_topic_above_suggested_ad_sizes",
      targeting_keys: "dfp_target_topic_above_suggested_key_code",
      targeting_values: "dfp_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "dfp_mobile_post_bottom_code",
      sizes: "dfp_mobile_post_bottom_ad_sizes",
      targeting_keys: "dfp_target_post_bottom_key_code",
      targeting_values: "dfp_target_post_bottom_value_code"
    }
  };
  function getWidthAndHeight(placement, settings, isMobile) {
    let config, size;
    if (isMobile) {
      config = MOBILE_SETTINGS[placement];
    } else {
      config = DESKTOP_SETTINGS[placement];
    }
    if (!renderCounts[placement]) {
      renderCounts[placement] = 0;
    }
    const sizes = (settings[config.sizes] || "").split("|");
    if (sizes.length === 1) {
      size = sizes[0];
    } else {
      size = sizes[renderCounts[placement] % sizes.length];
      renderCounts[placement] += 1;
    }
    if (size === "fluid") {
      return {
        width: "fluid",
        height: "fluid"
      };
    }
    const sizeObj = {
      width: parseInt(splitWidthInt(size), 10),
      height: parseInt(splitHeightInt(size), 10)
    };
    if (!isNaN(sizeObj.width) && !isNaN(sizeObj.height)) {
      return sizeObj;
    }
  }
  function defineSlot(divId, placement, settings, isMobile, width, height, categoryTarget) {
    if (!settings.dfp_publisher_id) {
      return;
    }
    if (ads[divId]) {
      return ads[divId];
    }
    let ad, config, publisherId;
    if (isMobile) {
      publisherId = settings.dfp_publisher_id_mobile || settings.dfp_publisher_id;
      config = MOBILE_SETTINGS[placement];
    } else {
      publisherId = settings.dfp_publisher_id;
      config = DESKTOP_SETTINGS[placement];
    }
    ad = window.googletag.defineSlot("/" + publisherId + "/" + settings[config.code], [width, height], divId);
    custom_targeting(keyParse(settings[config.targeting_keys]), keyParse(settings[config.targeting_values]), ad);
    if (categoryTarget) {
      ad.setTargeting("discourse-category", categoryTarget);
    }
    ad.addService(window.googletag.pubads());
    ads[divId] = {
      ad,
      width,
      height
    };
    return ads[divId];
  }
  function destroySlot(divId) {
    if (ads[divId] && window.googletag) {
      window.googletag.destroySlots([ads[divId].ad]);
      delete ads[divId];
    }
  }
  function loadGoogle() {
    /**
     * Refer to this article for help:
     * https://support.google.com/admanager/answer/4578089?hl=en
     */

    if (_loaded) {
      return _rsvp.default.resolve();
    }
    if (_promise) {
      return _promise;
    }

    // The boilerplate code
    let dfpSrc = ("https:" === document.location.protocol ? "https:" : "http:") + "//securepubads.g.doubleclick.net/tag/js/gpt.js";
    _promise = (0, _loadScript.default)(dfpSrc, {
      scriptTag: true
    }).then(function () {
      _loaded = true;
      if (window.googletag === undefined) {
        // eslint-disable-next-line no-console
        console.log("googletag is undefined!");
      }
      window.googletag.cmd.push(function () {
        // Infinite scroll requires SRA:
        window.googletag.pubads().enableSingleRequest();

        // we always use refresh() to fetch the ads:
        window.googletag.pubads().disableInitialLoad();
        window.googletag.enableServices();
      });
    });
    window.googletag = window.googletag || {
      cmd: []
    };
    return _promise;
  }
  var _default = _exports.default = _adComponent.default.extend((_dec = (0, _decorators.default)("siteSettings.dfp_publisher_id", "siteSettings.dfp_publisher_id_mobile", "site.mobileView"), _dec2 = (0, _decorators.default)("placement", "postNumber"), _dec3 = (0, _decorators.default)("placement", "showAd"), _dec4 = (0, _decorators.default)("width", "height"), _dec5 = (0, _decorators.default)("width"), _dec6 = (0, _decorators.default)("publisherId", "showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage", "size"), _dec7 = (0, _decorators.default)("currentUser.trust_level"), _dec8 = (0, _decorators.default)("postNumber"), _dec9 = (0, _decorators.on)("didUpdate"), _dec10 = (0, _decorators.on)("didInsertElement"), _dec11 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    classNameBindings: ["adUnitClass"],
    classNames: ["google-dfp-ad"],
    loadedGoogletag: false,
    refreshOnChange: null,
    lastAdRefresh: null,
    width: (0, _computed.alias)("size.width"),
    height: (0, _computed.alias)("size.height"),
    size() {
      return getWidthAndHeight(this.get("placement"), this.siteSettings, this.site.mobileView);
    },
    publisherId(globalId, mobileId, isMobile) {
      if (isMobile) {
        return mobileId || globalId;
      } else {
        return globalId;
      }
    },
    divId(placement, postNumber) {
      let slotNum = getNextSlotNum();
      if (postNumber) {
        return `div-gpt-ad-${slotNum}-${placement}-${postNumber}`;
      } else {
        return `div-gpt-ad-${slotNum}-${placement}`;
      }
    },
    adUnitClass(placement, showAd) {
      return showAd ? `dfp-ad-${placement}` : "";
    },
    adWrapperStyle(w, h) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)(`width: ${w}px; height: ${h}px;`);
      }
    },
    adTitleStyleMobile(w) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)(`width: ${w}px;`);
      }
    },
    showAd(publisherId, showToTrustLevel, showToGroups, showAfterPost, showOnCurrentPage, size) {
      return publisherId && showToTrustLevel && showToGroups && showAfterPost && showOnCurrentPage && size;
    },
    showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.dfp_through_trust_level);
    },
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.dfp_nth_post_code, 10));
    },
    // 3 second delay between calls to refresh ads in a component.
    // Ember often calls updated() more than once, and *sometimes*
    // updated() is called after _initGoogleDFP().
    shouldRefreshAd() {
      const lastAdRefresh = this.get("lastAdRefresh");
      if (!lastAdRefresh) {
        return true;
      }
      return new Date() - lastAdRefresh > 3000;
    },
    updated() {
      if (this.get("listLoading") || !this.shouldRefreshAd()) {
        return;
      }
      let slot = ads[this.get("divId")];
      if (!(slot && slot.ad)) {
        return;
      }
      let ad = slot.ad,
        categorySlug = this.get("currentCategorySlug");
      if (this.get("loadedGoogletag")) {
        this.set("lastAdRefresh", new Date());
        window.googletag.cmd.push(() => {
          ad.setTargeting("discourse-category", categorySlug || "0");
          window.googletag.pubads().refresh([ad]);
        });
      }
    },
    _initGoogleDFP() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }
      if (!this.get("showAd")) {
        return;
      }
      loadGoogle().then(() => {
        this.set("loadedGoogletag", true);
        this.set("lastAdRefresh", new Date());
        window.googletag.cmd.push(() => {
          let slot = defineSlot(this.get("divId"), this.get("placement"), this.siteSettings, this.site.mobileView, this.get("width"), this.get("height"), this.get("currentCategorySlug") || "0");
          if (slot && slot.ad) {
            // Display has to be called before refresh
            // and after the slot div is in the page.
            window.googletag.display(this.get("divId"));
            window.googletag.pubads().refresh([slot.ad]);
          }
        });
      });
    },
    willRender() {
      this._super(...arguments);
      if (!this.get("showAd")) {
        return;
      }
    },
    cleanup() {
      destroySlot(this.get("divId"));
    }
  }, (_applyDecoratedDescriptor(_obj, "size", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "size"), _obj), _applyDecoratedDescriptor(_obj, "publisherId", [_dec], Object.getOwnPropertyDescriptor(_obj, "publisherId"), _obj), _applyDecoratedDescriptor(_obj, "divId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "divId"), _obj), _applyDecoratedDescriptor(_obj, "adUnitClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "adUnitClass"), _obj), _applyDecoratedDescriptor(_obj, "adWrapperStyle", [_dec4], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyle"), _obj), _applyDecoratedDescriptor(_obj, "adTitleStyleMobile", [_dec5], Object.getOwnPropertyDescriptor(_obj, "adTitleStyleMobile"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec6], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec8], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj), _applyDecoratedDescriptor(_obj, "updated", [_dec9], Object.getOwnPropertyDescriptor(_obj, "updated"), _obj), _applyDecoratedDescriptor(_obj, "_initGoogleDFP", [_dec10], Object.getOwnPropertyDescriptor(_obj, "_initGoogleDFP"), _obj), _applyDecoratedDescriptor(_obj, "cleanup", [_dec11], Object.getOwnPropertyDescriptor(_obj, "cleanup"), _obj)), _obj)));
});