define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads", ["exports", "discourse/lib/ajax", "discourse/routes/discourse", "@ember/object"], function (_exports, _ajax, _discourse, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    settings: null,
    model() {
      return (0, _ajax.ajax)("/admin/plugins/pluginad/house_creatives.json").then(data => {
        this.set("settings", _object.default.create(data.settings));
        return data.house_ads.map(ad => _object.default.create(ad));
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        model,
        houseAdsSettings: this.get("settings"),
        loadingAds: false
      });
    }
  });
});